import { MerchantUser, AcquirerUser } from '@root/globalTypes';

export const MATCH_LEVEL = 3;

export enum ESupportedLangs {
  en = 'en',
  ar = 'ar',
}

export const supportedLanguages: string[] = Object.values(ESupportedLangs);

export const getLangFromPath = () => {
  const p = window.location.pathname;
  const isSupportedLang = supportedLanguages.find((lang) => p.startsWith(`/${lang}`));
  if (isSupportedLang) return isSupportedLang;
  return 'en';
};

export const queryKeys = {
  acquirerApiKeys: ['acquirer-api-keys'],
  acquirerUsers: ['acquirer-users'],
  acquirerUserPermissions: ['acquirer-user-permissions'],
  merchantUsers: ['merchant-users'],
  merchantUserPermissions: ['merchant-user-permissions'],
  merchantApiKeys: ['merchant-api-keys'],
  merchants: ['merchants'],
  merchant: ['merchant'],
  transactions: ['transactions'],
  order: ['order'],
  orders: ['orders'],
  cardAuths: ['card-auths'],
  cardAuth: ['card-auth'],
  loggedUser: ['logged-user'],
  agreements: ['agreements'],
  acquirerLinks: ['acquirer-links'],
  paymentControls: ['payment_controls'],
  staffUsers: ['staff-users'],
  staffUserPermissions: ['staff-user-permissions'],
  staffAcquirers: ['staff-acquirers'],
  eodStatements: ['eod-statements'],
  merchantLinks: ['merchant-links'],
  auditLogs: ['audit-logs'],
  auditLogsSubjects: ['audit-logs-subjects'],
  acquirerApiKeyPermissions: ['acquirer-api-key-permissions'],
  apiKeyPermissions: ['api-key-permissions'],
  acquirerMerchants: ['acquirer-merchants'],
};

export function isMerchant(u: MerchantUser | AcquirerUser): u is MerchantUser {
  return (u as MerchantUser).merchantId !== undefined;
}

export const paymentControlsParams = [
  'bin_range',
  'bin_country',
  'pan',
  'fingerprint',
  'ip',
  'ip_country',
  'eci',
  '3ds_status',
] as const;

export type PaymentControlsTypes = (typeof paymentControlsParams)[number] | 'tds_status';
export const VALID_SCHEMES = ['visa', 'mastercard', 'amex', 'mada'] as const;
export const MERCHANT_RULE_PRECEDENCES = ['acquirer', 'merchant', 'mixed'] as const;
export const MERCHANT_CARD_AUTH_PREFERENCES = ['default', 'scheme', 'parent_scheme'] as const;
export const MERCHANT_VAULT_NAMES = [
  'moyasar_vault',
  'memory_vault',
  'cache_vault',
  'spg_vault',
] as const;

export const DEFAULT_ROWS_PER_PAGE = '50';
