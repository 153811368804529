import { Button } from '@components/button';
import { CountriesCombobox } from '@components/comboboxes/countries';
import { MerchantCategoriesCombobox } from '@components/comboboxes/merchantCategories';
import { TimezonesCombobox } from '@components/comboboxes/timezones';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useMerchantForm } from '@hooks/forms/merchants';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@components/form/select';
import {
  MERCHANT_RULE_PRECEDENCES,
  MERCHANT_CARD_AUTH_PREFERENCES,
  MERCHANT_VAULT_NAMES,
} from '@utils/consts';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@utils/textHelpers';

export const MerchantForm = ({
  formObj,
  submitText,
}: {
  formObj: ReturnType<typeof useMerchantForm>;
  submitText: string;
}) => {
  const { t } = useTranslation('app');
  const { form, onSubmit, error, isPending } = formObj;

  return (
    <Form {...form}>
      <form
        id="merchantForm"
        className="space-y-8"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.merchantName.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="arabicName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.arabicName.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="merchantId"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.merchantId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="mcc"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel required>{t('form.mcc.label')}</FormLabel>
                <FormControl>
                  <MerchantCategoriesCombobox
                    value={value}
                    onSelect={onChange}
                    className="w-full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="rulePrecedence"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>{t('form.rulePrecedence.label')}</FormLabel>
                <Select onValueChange={onChange} value={value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('form.rulePrecedence.placeholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                  <SelectContent>
                    {MERCHANT_RULE_PRECEDENCES.map((rule) => (
                      <SelectItem key={rule} value={rule}>
                        {capitalizeFirstLetter(rule)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cardAuthPreference"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>{t('form.cardAuthPreference.label')}</FormLabel>
                <Select onValueChange={onChange} value={value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('form.cardAuthPreference.placeholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                  <SelectContent>
                    {MERCHANT_CARD_AUTH_PREFERENCES.map((preference) => (
                      <SelectItem key={preference} value={preference}>
                        {capitalizeFirstLetter(preference)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vaultName"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>{t('form.vaultName.label')}</FormLabel>
                <Select onValueChange={onChange} value={value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('form.vaultName.placeholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                  <SelectContent>
                    {MERCHANT_VAULT_NAMES.map((name) => (
                      <SelectItem key={name} value={name}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tradingName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.tradingName.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="crNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.crNumber.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="unifiedNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.unifiedNumber.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="goodsDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.goodsDescription.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.website.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timezone"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel required>{t('form.timezone.label')}</FormLabel>
                <TimezonesCombobox value={value} onSelect={onChange} className="w-full" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="madaTerminalId"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.madaTerminalId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="visaTerminalId"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.visaTerminalId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="masterTerminalId"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.masterTerminalId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cardAuthProvider"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel>{t('form.cardAuthProvider.label')}</FormLabel>
                <Select onValueChange={onChange} value={value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t('form.cardAuthProvider.placeholder')} />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                  <SelectContent>
                    {['endeavour'].map((name) => (
                      <SelectItem key={name} value={name}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endeavourMerchantId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.endeavourMerchantId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          <legend className="col-span-full mt-2 font-medium">{t('form.addressDetails')}</legend>
          <FormField
            control={form.control}
            name="address.country"
            render={({ field: { value, onChange } }) => (
              <FormItem>
                <FormLabel required>{t('form.country.label')}</FormLabel>
                <CountriesCombobox value={value} onSelect={onChange} className="w-full" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.state.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.city"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.city.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.zip"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.zip.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.company"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.company.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.line1"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.line1.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address.line2"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('form.line2.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          <legend className="col-span-full mt-2 font-medium">{t('form.visaSettings')}</legend>
          <FormField
            control={form.control}
            name="visaSettings.identifier"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.identifier.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="visaSettings.verificationValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.verificationValue.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="visaSettings.marketIndicator"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.marketIndicator.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
          <legend className="col-span-full mt-2 font-medium">{t('form.mastercardSettings')}</legend>
          <FormField
            control={form.control}
            name="mastercardSettings.assignedId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.assignedId.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {error && <p className="text-red">{error}</p>}

        <Button
          className="mt-2"
          size="lg"
          variant="primary"
          type="submit"
          disabled={isPending}
          loading={isPending}>
          {submitText}
        </Button>
      </form>
    </Form>
  );
};
