import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@components/accordion';
import { Button } from '@components/button';
import { Card, CardContent } from '@components/card';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { ObjectContent } from '@components/objectContent';
import { RawPreview } from '@components/rawPreview';
import { TabsContainer } from '@components/tabLink';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useAppContext } from '@hooks/useAppContext';
import { BaseLayout } from '@layouts/base';
import { Merchant } from '@root/globalTypes';
import { Outlet, useNavigate, useParams } from '@tanstack/react-router';
import { formatDate } from '@utils/dateHelpers';
import { mapSchemeToAsset } from '@utils/fileHelpers';
import { capitalizeFirstLetter } from '@utils/textHelpers';
import {
  BadgeInfo,
  Blocks,
  BookA,
  CalendarCog,
  CalendarPlus,
  Contact,
  ExternalLink,
  Fingerprint,
  Landmark,
  Link,
  SquareLibrary,
  Store,
  Vault,
  Warehouse,
  List,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function MerchantPage({
  data,
  handleEditRedirect,
  tabs,
}: {
  data: Merchant;
  tabs: React.ReactNode[];
  handleEditRedirect: () => void;
}) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { localId } = useParams({ strict: false });
  const { userType } = useAppContext();

  return (
    <BaseLayout
      title={data.merchantId}
      isChild
      actions={[
        verifyUserPermission('merchants_update') && (
          <Button key="edit" variant="primary" onClick={handleEditRedirect}>
            {t('actions.edit')}
          </Button>
        ),
      ]}>
      <DetailsCard>
        <DetailsCardItem icon={<Fingerprint />} data={data.id} label={t('table.id')} column={1} />
        <DetailsCardItem icon={<Store />} data={data.name} label={t('table.name')} column={1} />
        <DetailsCardItem
          icon={<BookA />}
          data={data.arabicName}
          label={t('table.arabicName')}
          column={1}
        />
        <DetailsCardItem
          icon={<Warehouse />}
          data={data.tradingName}
          label={t('table.tradingName')}
          column={1}
        />
        <DetailsCardItem
          icon={<Fingerprint />}
          data={data.crNumber}
          label={t('table.crNumber')}
          column={1}
        />
        <DetailsCardItem
          icon={<SquareLibrary />}
          data={data.mcc}
          label={t('table.mcc')}
          column={1}
        />
        <DetailsCardItem
          icon={<Fingerprint />}
          data={data.unifiedNumber}
          label={t('table.unifiedNumber')}
          column={1}
        />
        <DetailsCardItem
          icon={<BadgeInfo />}
          data={data.goodsDescription}
          label={t('table.goodsDescription')}
          column={1}
        />
        <DetailsCardItem
          icon={<List />}
          data={data.cardAuthProvider}
          label={t('table.cardAuthProvider')}
          column={1}
        />
        <DetailsCardItem
          icon={<Contact />}
          data={data.endeavourMerchantId}
          label={t('table.endeavourMerchantId')}
          column={1}
        />
        <DetailsCardItem
          icon={<Vault />}
          data={data.vaultName}
          label={t('table.vaultName')}
          column={userType === 'acquirer' ? 2 : 1}
        />
        <DetailsCardItem
          icon={<Blocks />}
          data={capitalizeFirstLetter(data.rulePrecedence)}
          label={t('table.rulePrecedence')}
          column={2}
        />
        <DetailsCardItem
          icon={<List />}
          data={data.cardAuthPreference}
          label={t('table.cardAuthPreference')}
          column={2}
        />
        {userType === 'staff' && (
          <>
            <DetailsCardItem
              icon={<Fingerprint />}
              data={
                <a
                  onClick={() => {
                    navigate({
                      to: '/u/$localId/staff/acquirers/$acquirerId',
                      params: { localId: localId || '0', acquirerId: data.acquirer.id },
                    }).catch((err) => console.error(err));
                  }}
                  className="inline-flex items-center gap-1 hover:cursor-pointer hover:text-blue-700 hover:underline">
                  {data.acquirer.id}
                </a>
              }
              label={t('table.acquirerId')}
              column={2}
            />
            <DetailsCardItem
              icon={<Landmark />}
              data={data.acquirer.name}
              label={t('table.acquirer')}
              column={2}
            />
          </>
        )}
        <DetailsCardItem
          icon={<img className="w-8" src={mapSchemeToAsset('mada')} alt={data.madaTerminalId} />}
          data={data.madaTerminalId}
          label={t('table.madaTerminalId')}
          column={2}
        />
        <DetailsCardItem
          icon={<img className="w-8" src={mapSchemeToAsset('visa')} alt={data.visaTerminalId} />}
          data={data.visaTerminalId}
          label={t('table.visaTerminalId')}
          column={2}
        />
        <DetailsCardItem
          icon={
            <img className="w-8" src={mapSchemeToAsset('mastercard')} alt={data.masterTerminalId} />
          }
          data={data.masterTerminalId}
          label={t('table.masterTerminalId')}
          column={2}
        />
        {data.website && (
          <DetailsCardItem
            icon={<Link />}
            data={
              <a
                target="blank"
                rel="nofollow noreferrer"
                className="inline-flex items-center gap-1 hover:text-blue-700 hover:underline"
                href={data.website}>
                {data.website}
                <ExternalLink size={16} />
              </a>
            }
            label={t('table.website')}
            column={2}
          />
        )}
        <DetailsCardItem
          icon={<CalendarPlus />}
          data={formatDate(new Date(data.createdAt))}
          label={t('table.createdAt')}
          column={2}
        />
        <DetailsCardItem
          icon={<CalendarCog />}
          data={formatDate(new Date(data.updatedAt))}
          label={t('table.updatedAt')}
          column={2}
        />
      </DetailsCard>

      <Card>
        <CardContent className="scroll-m-16 py-3 pb-10" id="outletNav">
          <TabsContainer>{tabs}</TabsContainer>
          <Outlet />
        </CardContent>
      </Card>

      <Accordion type="multiple" defaultValue={['address']} className="space-y-6">
        <AccordionItem value="address" className="border-0">
          <AccordionTrigger>{t('pages.merchants.address')}</AccordionTrigger>
          <AccordionContent>
            <ObjectContent translationKey="form" translationKeySuffix="label" data={data.address} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="visaSettings" className="border-0">
          <AccordionTrigger>{t('pages.merchants.visaSettings')}</AccordionTrigger>
          <AccordionContent>
            <ObjectContent translationKey="visaSettings" data={data.visaSettings} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="mastercardSettings" className="border-0">
          <AccordionTrigger>{t('pages.merchants.mastercardSettings')}</AccordionTrigger>
          <AccordionContent>
            <ObjectContent translationKey="mastercardSettings" data={data.mastercardSettings} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="apiResponse" className="border-0">
          <AccordionTrigger>{t('pages.merchants.apiResponse')}</AccordionTrigger>
          <AccordionContent>
            <RawPreview data={{ data }} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </BaseLayout>
  );
}
